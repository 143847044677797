define('ws-reports/routes/reports/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    authSession: Ember.inject.service(),

    model: function () {
      return this.store.query('report', {
        orderBy: 'uid',
        equalTo: this.get('authSession').uid
      });
    },

    actions: {}
  });
});