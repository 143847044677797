define('ws-reports/controllers/signin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    entry: {},
    error: null,

    authSession: Ember.inject.service(),

    actions: {
      signin() {
        var _this = this;
        if (this.get('entry').email == '' || this.get('entry').password == '') {
          this.set('error', 'email and password are mandatory');
        } else {
          let handleError = this.get('handleError').bind(this);
          this.get('authSession').authenticate(this.get('entry').email, this.get('entry').password, handleError).then(user => {
            if (user) {
              this.transitionToRoute('reports');
              this.set('error', null);
              _this.resetEntry();
            }
          });
        }
      }

    },

    handleError(error) {
      this.set('error', error.message);
    },

    resetEntry() {
      this.set('entry', {});
      this.set('error', null);
    }
  });
});