define('ws-reports/services/auth-session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    firebaseApp: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    user: null,
    uid: null,

    authenticate(email, password, errorCallback) {
      const auth = Ember.get(this, 'firebaseApp').auth();
      var _this = this;
      return auth.signInWithEmailAndPassword(email, password).then(user => {
        _this.set('uid', user.uid);
        _this.set('user', user.email);
        return user;
      }, error => {
        console.log(error.message);
        _this.invalidate();
        errorCallback(error);
      });
    },

    registerUser(email, password, errorCallback) {
      const auth = Ember.get(this, 'firebaseApp').auth();
      var _this = this;
      return auth.createUserWithEmailAndPassword(email, password).then(user => {
        return user;
      }, error => {
        console.log(error.message);
        _this.invalidate();
        errorCallback(error);
      });
    },

    invalidate() {
      this.set('uid', null);
      this.set('user', null);
    },

    isAuthenticated: Ember.computed.bool('accessToken')

  });
});