define('ws-reports/components/error-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    message: null,

    actions: {
      close() {
        this.set('message', null);
      }
    }

  });
});