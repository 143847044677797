define('ws-reports/routes/reports', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    authSession: Ember.inject.service(),

    beforeModel(transition) {
      console.log(this.get('authSession').user);
      if (!this.get('authSession').user) {
        this.transitionTo('signin');
      }
    },
    model: function () {
      return this.store.query('employee', {
        orderBy: 'uid',
        equalTo: this.get('authSession').uid
      });
    },
    // setupController(controller, model) {
    //     this._super(...arguments);
    //     if(model._length>0)
    //     {
    //       controller.set('employee', model[0]);
    //     }
    //   },


    actions: {
      logout() {
        this.get('authSession').invalidate();
        this.transitionTo('signin');
      }
    }
  });
});