define('ws-reports/router', ['exports', 'ws-reports/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('reports', function () {
      this.route('new');
    });
    this.route('signup');
    this.route('signin');
  });

  exports.default = Router;
});