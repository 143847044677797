define('ws-reports/controllers/signup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    entry: {},
    error: null,

    authSession: Ember.inject.service(),
    actions: {
      signup() {
        var _this = this;
        if (this.get('entry').email == '' || this.get('entry').password == '' || this.get('entry').firstname == '' || this.get('entry').lastname == '' || this.get('entry').confirmpassword == '') {
          this.set('error', 'All fields are mandatory');
        } else {
          if (this.get('entry').password != this.get('entry').confirmpassword) {
            this.set('error', 'Passwords do not match');
          } else {
            let handleError = this.get('handleError').bind(this);
            this.get('authSession').registerUser(this.get('entry').email, this.get('entry').password, handleError).then(user => {
              if (user) {
                var newEmployee = this.store.createRecord('employee', {
                  uid: user.uid,
                  firstname: this.get('entry').firstname,
                  lastname: this.get('entry').lastname
                });
                newEmployee.save();
                this.transitionToRoute('signin');
                _this.set('entry', {});
                _this.set('error', null);
              }
            });
          }
        }
      }
    },

    handleError(error) {
      this.set('error', error.message);
    }

  });
});