define('ws-reports/controllers/reports/new', ['exports', 'npm:aws-sdk', 'ws-reports/config/environment'], function (exports, _npmAwsSdk, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    accessKeyId: _environment.default.aws.accessKeyId,
    secretAccessKey: _environment.default.aws.secretAccessKey,
    entry: {},

    actions: {
      sendReport() {
        this.saveReport();
        //  console.log('Sending report');
        const submittedForm = {
          name: 'Name',
          email: 'slkraghav@gmail.com',
          company: 'Frontier',
          phone: '1212343234',
          title: this.get('entry').title,
          details: this.get('entry').details,
          notes: this.get('entry').notes,
          timestamp: new Date().getTime()
        };

        const emailParams = {
          Destination: { ToAddresses: ['slkraghav@gmail.com'] },
          Message: {
            Body: { Html: {
                Charset: "UTF-8",
                Data: `${submittedForm.name}'s Status report for this week.
              <br><br>
              <table style="width:80%">
              <tr>
              <th style="border: 1px solid;">Task </th>
              <th style="border: 1px solid;">Description</th>
              <th style="border: 1px solid;">Notes</th>
              </tr>
              <tr>
              <td style="border: 1px solid;"> ${submittedForm.title} </td>
              <td style="border: 1px solid;">${submittedForm.details}</td>
              <td style="border: 1px solid;">${submittedForm.notes}</td>
              </tr>
              </table>

               <br><br> You can contact them by email ${submittedForm.email} or phone ${submittedForm.phone}` } },
            Subject: { Data: 'Status Report', Charset: 'UTF-8' }
          },
          ReplyToAddresses: [submittedForm.email],
          Source: `raghavslk@gmail.com` // this has to be verified email in SES
        };
        const ses = new _npmAwsSdk.default.SES({
          apiVersion: '2010-12-01',
          accessKeyId: this.get('accessKeyId'),
          secretAccessKey: this.get('secretAccessKey'),

          "region": "us-west-2"
        });
        ses.sendEmail(emailParams, function (error, data) {
          if (error) {
            // console.log(error);
          } else {
              //console.log('Report sent successfully');
            }
        });

        //  console.log(submittedForm);
        //  console.log(ses);
      }
    },
    saveReport() {
      var nowDate = new Date();
      var _this = this;
      var report = this.store.createRecord('report', {
        'client': 'Statefarm Insurance',
        'location': 'Bloomington, IL',
        'reportDate': nowDate,
        'uid': 'RaV5EFBcSzSYM4YPnn44oWL9sGy1'
      });
      var task1 = this.store.createRecord('task', {
        'title': this.get('entry').title,
        'details': this.get('entry').details,
        'notes': this.get('entry').notes
      });
      // var task2 = this.store.createRecord('task',{
      //   'title':'ABCD Title',
      //   'details':'ABCD work details description hera dn there and so many things.',
      //   'notes':'None'
      // });
      report.get('tasks').pushObject(task1);
      //  report.get('tasks').pushObject(task2);
      task1.save().then(function () {
        report.save();
        _this.transitionToRoute('reports');
      });
    }

  });
});